<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{active : modelValue}">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModel"><i class="fas fa-long-arrow-alt-left"></i></button>
            <template v-if="deletedContactLoader">
                <div class="tabs_content">
                    <div class="content_area">
                        <div class="section_content w-100">
                            <div class="loader_area"><quote-loader/></div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="tabs_content">
                    <div class="content_area">
                        <div class="section_content w-100">
                            <div class="section_header">
                                <h2>Deleted Contacts</h2>
                            </div>
                            <div class="result_wpr new mt-2">
                                <div class="actions">
                                    <ul class="left"></ul>
                                    <ul class="right">
                                        <li class="optionDrops search_area ml-auto" :class="{'active': searchField}">
                                            <input type="text" @input="isTyping = true" v-model.trim="params.search"  placeholder="Search"/>
                                            <button class="search_btn" @click="searchField = !searchField;">
                                                <i class="fas fa-search"></i>
                                            </button>
                                        </li>
                                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePageFilter">
                                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                            <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="per-page-filter" />
                                        </li>
                                    </ul>
                                </div>
                                <table class="show_header">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Date Deleted </th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>User Responsible </th>
                                            <th>Feature Used</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(contact, c) of detetedContact.data" :key="c">
                                            <td></td>
                                           <td>{{ moment.utc(contact.updated_at).tz(user.timezone ? user.timezone : 'America/New_York').format('ll') }}</td>
                                           <td>
                                                <div class="user_wpr">
                                                    <div class="user_img">
                                                        <img :src="contact ? contact.photo : ''" alt="Contact Image">
                                                    </div>
                                                    <h4>{{ contact.name }}</h4>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="email_wpr">
                                                    <span class="email_add">{{ contact ? contact.email : '-' }}</span>
                                                </div>
                                            </td>
                                           <td>{{ contact.user_responsible ? contact.user_responsible.full_name : contact.employer.full_name }}</td>
                                           <td>{{ contact.feature_used ? contact.feature_used : '-' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table_footer">
                                <ul>
                                    <li>{{ detetedContact.from ? detetedContact.from : 0 }} - {{ detetedContact.to ? detetedContact.to : 0 }} of {{ detetedContact.total }}</li>
                                </ul>
                            </div>
                            <div class="pagination w-100" v-if="detetedContact.total">
                                <pagination :range-size="0" v-model="params.page" :pages="detetedContact.last_page" @update:modelValue="handlePagination" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment-timezone'

    export default {
        name: 'deleted contact ',

        data () {
            return {
                params: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    order: 'desc',
                    order_by: 'id',
                },
                isTyping: true,
                moment,
                scheduleList: false,
                searchField: false,
            }
        },

        props: {
            modelValue: Boolean,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue(val){
                const vm = this;

                if (val) {
                    vm.getDeletedContact(vm.params);
                }
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2 || vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getDeletedContact(vm.params);
                        }
                    }
                }
            },

            'params.per_page' () {
                const vm = this;
                vm.params.page = 1;

                vm.getDeletedContact(vm.params);
            },
        },

        computed: mapState({
            detetedContact: state => state.contactModule.detetedContact,
            deletedContactLoader: state => state.contactModule.deletedContactLoader,
            user: state => state.authModule.user,
        }),
         
        methods:{
            ...mapActions({
                getDeletedContact: 'contactModule/getDeletedContact',
            }),

            closeModel () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getDeletedContact(vm.params);
            },

            togglePerPageFilter (value = undefined) {
                const vm = this;

                const filter = vm.$refs['per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePageFilter () {
                const vm = this;
                const filter = vm.$refs['per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },
        }
    }
</script>

<style scoped>
    .global_setting .tabs_content::-webkit-scrollbar {
        width: 4px;
        display: block;
    }

    .global_setting .tabs_content:hover::-webkit-scrollbar-thumb {
        background-color: #b8b8b8;
        border-radius: 2px;
    }

    .modal.schedule .modal_container {
        height: 640px;
    }

    .schedule .modal_header {
        padding: 20px 30px 0;
    }

    .schedule .modal_body {
        padding: 15px 30px;
        flex-direction: column;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }
    .result_wpr table{
        min-width: 1000px !important;
    }
    .result_wpr .actions > ul li.edit{
        width: 30px;
        height: 30px;
        background: #fbfbfb;
        border-radius: 50%;
        padding: 0;
    }

    .expanded .loader_area {
        left: 0;
    }

    @media(max-width: 767px){
        .result_wpr.new .actions > ul li{
            padding: 7px;
        }
        .result_wpr.new .actions > ul li.search_area{
            width: 100%;
            order: -1;
        }
        .result_wpr.new .actions > ul li.search_area input[type=text]{
            padding: 0 15px 0 0;
        }
        .result_wpr.new .actions > ul li.sort_list{
            margin-left: auto;
        }
    }
    @media(max-width: 450px){
        .result_wpr.new .actions > ul li:first-child{
            width: 50%;
        }
        .result_wpr.new .actions > ul li.contacts-tabs{
            order: 1;
        }
        .result_wpr.new .actions > ul li.edit{
            order: 1;
            margin-left: auto;
        }
    }
</style>